<template>
  <div class="flex flex-col flex-1">
    <Topbar :pages="pages"> </Topbar>
    <div class="flex flex-col p-6 flex-1">
      <p class="text-center" v-if="isLoading">Loading...</p>
      <div class="border-b pb-2" style="height: 75vh" v-if="!isLoading">
        <Map :archives="archives"></Map>
      </div>
    </div>
  </div>
</template>

<script>
import {listSurveys, listSurveyResults} from '../../graphql/queries';
import API, {graphqlOperation} from '@aws-amplify/api';
import Map from './Map';
import moment from 'moment-timezone';
import Topbar from '@/components/Topbar';

export default {
  components: {
    Map,
    Topbar,
  },
  computed: {
    pages() {
      return [
        {
          name: 'Dashboard',
          to: {name: 'home'},
          current: false,
        },
        {
          name: 'Maps',
          to: {name: 'maps'},
          current: true,
        },
      ];
    },
  },
  data() {
    return {
      archives: [],
      survey: null,
      isLoading: true,
      filterValues: {},
      filterGroup: {},
      group_by: 'district',
    };
  },
  methods: {
    async loadSurvey() {
      try {
        await API.graphql(graphqlOperation(listSurveys)).then(
          async ({
            data: {
              listSurveys: {items: items},
            },
          }) => {
            if (items.length > 0) {
              this.survey = items[0];
              await this.loadArchives();
              this.isLoading = false;
            }
          },
        );
      } catch (error) {
        this.error = error.data?.message || error.statusText;
        this.isLoading = false;
      }
    },
    async loadArchives() {
      try {
        await API.graphql(graphqlOperation(listSurveyResults, {survey_id: this.survey.id})).then(
          ({
            data: {
              listSurveyResults: {items},
            },
          }) => {
            this.archives = items
              .map(item => {
                item.survey_result = JSON.parse(item.survey_result);
                const gps_point = item.survey_result?.gps_point;
                console.log(item, 'item');
                return {
                  gps_point: gps_point ? gps_point.split(',') : null,
                  createdAt: moment(item.createdAt).toString(),
                  district: item.survey_result?.district,
                  divisional_secretariat: item.survey_result?.divisional_secretariat,
                  gn_division: item.survey_result?.gn_division,
                };
              })
              .filter(item => {
                return !!item.gps_point;
              });
          },
        );
      } catch (error) {
        this.error = error.data?.message || error.statusText;
      }
    },
  },
  async created() {
    this.isLoading = true;
    await this.loadSurvey();
  },
};
</script>
